@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-main-purple: #4e46e5;
    --color-main-purple-light: #8283f0;
    --color-main-purple-extra-light: #cbc9f8;
    --color-main-purple-dark: #4031cd;
    --color-main-purple-light2: #d7d4fb;
    --color-main-purple-extra-dark: #2b00b0;
    --color-main-blue: #55608d;
    --color-main-blue-extra-light: #c9cedd;
    --color-main-blue-light: #8690ad;
    --color-main-blue-dark: #454e79;
    --color-main-blue-extra-dark: #323452;
    --color-main-gray: #9e9e9e;
    --color-main-gray-light: #e0e0e0;
    --color-main-gray-extra-light: #fafafa;
    --color-main-gray-dark: #616161;
    --color-main-gray-extra-dark: #212121;
    --color-main-green: #53c41a;
    --color-main-green-light: #8dd66b;
    --color-main-green-extra-light: #d0eec0;
    --color-main-green-dark: #26a001;
    --color-main-green-extra-dark: #006a00;
    --color-main-red: #f32e26;
    --color-main-red-light: #e6696a;
    --color-main-red-extra-light: #ffc9ce;
    --color-main-red-dark: #d31121;
    --color-main-red-extra-dark: #b7000c;
    --color-main-white: #ffffff;

    /* warning colors */

    --color-warning-100: #ffecd9;

    --color-warning-500: #ff9f43;

    --color-warning-900: #623d1a;

    --color-info-success-100: #D4F4E2;
    --color-info-success-500: #28C76F;
    --color-info-submitted-100: #CCF5FA;
    --color-info-submitted-500: #00CFE8;
    --color-info-danger-500: #EA5455;
    --color-info-danger-100: #FFF3F3;
  }
}

body {
  @apply font-inter;
}

.editor-container .ql-container {
  min-height: 200px;
  min-width: 200px;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #d0d5dd !important;
  border-radius: 8px;
}
.editor-container .ql-toolbar {
  border: none !important;
}

.ql-editor.ql-blank {
  height: 200px;
}

::-webkit-scrollbar {
  height: 2px;
  width: 4px;
}

::-webkit-scrollbar-track {
  border-radius: 0.5rem;
  background-color: #f0f0f0;
  padding: 3px;
}

::-webkit-scrollbar-thumb {
  background-color: #8780f2;
  border-radius: 0.5rem;
  padding: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #8780f2;
}

.quill-editor .ql-editor::-webkit-scrollbar{
  background-color: #f0f0f0 !important;
  width: 2px !important;
}
.quill-editor .ql-editor::-webkit-scrollbar-thumb{
  background-color: #8780f2 !important;
}
